<template>
  <div class="package-form">
    <v-form ref="form" @submit.prevent="validateForm">
      <v-row>
        <v-col cols="8">
          <v-text-field
              outlined
              dense
              class="label-input input-number"
              :label="$t('Package.form.min_user')"
              :rules="nameRules"
              type="number"
              v-model="items.min_user"
              :hint="`${server_errors.min_user}`"
              persistent-hint
              @keydown.enter="focusNext"
          >
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
              @keydown.enter="focusNext"
              outlined
              dense
              class="label-input input-number"
              :label="$t('Package.form.user')"
              :rules="nameRules"
              type="number"
              v-model="items.max_user"
              :hint="`${server_errors.max_user}`"
              persistent-hint
          >
          </v-text-field>
        </v-col>

        <v-col cols="8">
          <vuetify-money
              :label="$t('Package.form.pricePerUser')"
              dense
              :outlined="true"
              :options="$store.state.formatSalaryNumber"
              :clearable="true"
              :rules="nameRules"
              v-model="items.price_per_user"
              :hint="`${server_errors.price_per_user}`"
              persistent-hint
              @keydown.prevent.enter="validateForm"
          />
        </v-col>
        <v-col cols="8">
          <v-btn class="btn-save-change" @click="validateForm">
            {{ $t("Package.form.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      btnLoading: false,
      items: {
        max_user: "",
        min_user: "",
        price_per_user: "",
      },

      server_errors: {
        max_user: "",
        price_per_user: "",
        min_user: "",
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input,v-input,vuetify-money'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    saveChange() {
      this.$axios.post(`admin/add/package`, this.items).then((res) => {
        if (res.data.success === true) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
        this.btnLoading = false;
        this.resetForm();
        this.$router.push({
          name: "package.index"
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.package-form {
  width: 100%;
  height: 600px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
